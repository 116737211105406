import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBIcon,MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter   } from "mdbreact";
import {Backend, Form,Roles} from "../../data/index";
import { toast } from 'react-toastify';
import Select from 'react-select';
import config from '../../config';


const { 
        GetFormularyByServices,
        GetServicesByInstituion, 
        GetLastAllInstitutions,
        GetLastversionFormByService,
        GetFormularyByServiceBK
      } = Backend
const {saveRol} = Roles
const {saveForm} = Form
class NewForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            modal: false,
            shadow: '',
            idForm: '',
            Form: [],
            idService: '0',
            idInsti: '0',
            error: '',
            imgUrl:'',
            loading: false,
            institutions: [],
            services: [],
            selectedOptionInstitution: null,
            selectedOptionService: null,
            modalError: false,
            errorSE: {},
            disabled: true,
        }
        this.idInsti = this.idInsti.bind(this);
        this.idService = this.idService.bind(this);
    }    
    async componentDidMount(){
        const institutions = await GetLastAllInstitutions();
        const options = [];
        try{
        for (const i in institutions.data) {
            options.push( { value: institutions.data[i].id, label: institutions.data[i].id +'|'+ institutions.data[i].name });
        }
      }catch(e){
      }
        this.setState({
            institutions: options
        })
    }
    toggleError = () => {
      this.setState({
        modalError: !this.state.modalError
      });
    }
    toggle = () => {
        this.setState({
          modal: !this.state.modal
        });
      }
     
      idService(e){
        this.setState({
          idService: e.target.value
        })
      }
      async idInsti(e){
          let id = await e.target.value || '0'
        const services = await GetServicesByInstituion(id)
        this.setState({
            idInsti: id,
            services: services.data
        })
      }
      handleChange = async selectedOptionInstitution => {
        this.setState({ selectedOptionInstitution });
        let services = await GetServicesByInstituion(selectedOptionInstitution.value)
        services = services.data.filter(function (el) {
          return el.provider === 'portal'
        });
        
        const options = [];
        for (const i in services) {
            options.push( { value: services[i].id, label: services[i].name  });
        }
        this.setState({
            idInsti: selectedOptionInstitution.value,
            services: options
        })
      };

      handleChangeService = async selectedOptionService => {
        this.setState({
          loading: true
      })
          let result = await GetFormularyByServiceBK(selectedOptionService.value)
          console.log('formBk********', result)
          if(result.data.Form.success){
            this.setState({
                idForm: result.data.Form.name,
                Form: result.data.Form,
                loading:false,
                disabled: false,
                error:'',
                idService: selectedOptionService.value,
                  selectedOptionService
              })
          }


          if(!result.data.Form.success){
            this.setState({
                
                loading:false,
                error: result.data.Form.message,
                disabled: true,
              })
          }
       
      };

       async saveForm(){
        this.setState({
            loading: true
        })
        
        let form = this.state.Form
              if(this.state.idInsti==='' || this.state.idService===''){
                  this.setState({error: 'todos los campos tienen que estar llenos'})
              }else{
                
                const servicesExist = await GetFormularyByServices(this.state.idService)
                if(servicesExist.data.servicesExist){
                  this.setState({error: 'Este servicio ya esta registrado',loading: false})
                  return 
                }
              
                
                this.setState({error: ''})
                let body = {
                jsonForm:form.fields,
                idForm: this.state.idForm,
                idservice: parseInt( this.state.idService),
                idinstitution: parseInt(this.state.idInsti),
                url:document.getElementById('imgUrl').value,
                entity: '',
                version: form.version,
                provider: 'bk'
              }
              const result = await saveForm(body);
              console.log('result form bk', result)
                  if(result.data.form){
                    let body = {
                      idUser: localStorage.getItem('idUser'),
                      idForm: result.data.form.id
                    }
                    let UserFull = {
                      idUser: '102',
                      idForm: result.data.form.id
                    }
                    const rol = await saveRol(body);
                    const rolUserFull = await saveRol(UserFull);
                      this.setState({modal: false})
                      this.props.handlerData()
                  }else{
                      this.setState({error: 'Hubo un error al guardar el formulario por favor revisar el log'})
                  }
              }
        
        this.setState({
            loading: false
        })
      }
    render() {
        return (
        <div className="App padding">
        
                <MDBCol onClick={this.toggle} onMouseOut={()=> this.setState({shadow: ''})} onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})}  className={"border radius padding " +this.state.shadow }>
                    <MDBIcon  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} icon="plus-circle" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}  size="2x" className=" cursor"></MDBIcon>
                    <h6  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})}>Nuevo Formulario de BK</h6>
                </MDBCol>
                
       
        {/** ===========================MODAL===================================*/}
        <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg" >
            <MDBModalHeader toggle={this.toggle}>Nuevo Formulario</MDBModalHeader>
            <MDBModalBody>
            <p className="h4 text-center mb-4">Busca un formulario en el backend </p>
            <MDBRow>
                        <MDBCol>
                        <label htmlFor="defaultFormLoginEmailEx" >
                           institucion
                             </label>
                             <Select
                             value={this.state.selectedOptionInstitution}
                             onChange={this.handleChange}
                             options={this.state.institutions}
                             placeholder="seleccione una institucion"
                              />
                             {this.state.loadingForRule&&(
                               <div>
                                 <MDBIcon icon="cog" spin size="2x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} fixed />
                                 <label>Asignando regla....</label>
                               </div>
                               )}
                        </MDBCol>
                        <MDBCol>
                             <label htmlFor="defaultFormLoginEmailEx" > Servicio </label>
                                  <Select
                                    value={this.state.selectedOptionService}
                                    onChange={this.handleChangeService}
                                    options={this.state.services}
                                    placeholder="seleccione una institucion"
                                      />
                                  {this.state.loadingForRule&&(
                                    <div>
                                      <MDBIcon icon="cog" spin size="2x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#757575'}}fixed />
                                      <label>Asignando regla....</label>
                                    </div>
                                    )}
                        </MDBCol>
                        
                        </MDBRow>
                        <br />  
                        <label htmlFor="defaultFormLoginEmailEx" >
                        Nombre
                        </label>
                        <input
                        type="text"
                        id="id_formulary"
                        name="id_formulary"
                        className="form-control"
                        value={ this.state.idForm }
                        disabled
                        autoComplete /> 
                        {this.state.loading&&(
                            <MDBIcon icon="cog" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />
                        )}
                        <br />
                        
                             <label htmlFor="defaultFormLoginEmailEx" > Imagen de la Institución (URL) </label>
                             <input
                              type="text"
                              className="form-control"
                              id="imgUrl"
                              />
                            
            </MDBModalBody>
            <MDBModalFooter>
            <center><label className="red-text">{this.state.error}</label></center>
            <MDBBtn color={config.color} disabled={this.state.idInsti ==='0' || this.state.idService ==='0' || this.state.disabled?
             true: false} onClick={()=> this.saveForm()}>Guardar</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        </MDBContainer>

        {/**==================================MODAL ERROR=============== */}
        <MDBModal isOpen={this.state.modalError} toggle={this.toggleError} side position="bottom-right">
        <MDBModalHeader toggle={this.toggleError}>{this.state.errorSE.error}</MDBModalHeader>
        <MDBModalBody>
          <label><b>Razon:</b> {this.state.errorSE.detail}</label><br/>
          <label><b>Campo:</b> {this.state.errorSE.field&&this.state.errorSE.field.label }</label><br/>
          <label><b>Id:</b> {this.state.errorSE.field&&this.state.errorSE.field.name}</label>

        </MDBModalBody>
      </MDBModal> 
    
        </div>
        );
    }
}
export default NewForm;