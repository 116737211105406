//=================================== CHANGE ENVIRONMENT ==================================
    const option = 'production'
//=========================================================================================

function env(env) {
    switch (env) {
        case 'local':
            return ['http://localhost:4200/rules/api',env,'grey darken-1','http://des-apprr.serviciosrd.gob.do/']
        case 'server-lovera':
            return ['http://45.35.97.22/node/api-rules/rules/api',env,'secondary-color','http://des-apprr.serviciosrd.gob.do/']
        case 'development':
            return ['http://45.229.148.33/rulesd',env,'blue darken-4','http://des-apprr.serviciosrd.gob.do/']
        case 'production':
            //return ['http://45.229.148.33/rulesp',env, 'teal darken-1','http://apprr.serviciosrd.gob.do/index.php']
            return ['https://reglas-api.serviciosrd.gob.do',env, 'teal darken-1','http://apprr.serviciosrd.gob.do/index.php']
        default:
            return ''
    }
}
try{
    module.exports ={
        api:    env(option)[0],
        env:    env(option)[1],
        color:  env(option)[2],
        qrApi: env(option)[3],
        SECRET_TOKEN: 'palolo'
    }
}catch(e){

}
