export default {
    '0':{ mask: '0', label: '0 - CEDULA'},
    '1':{ mask: '1', label: '1 - RNC'},
    '2':{ mask: '2', label: '2 - TELEFONO'},
    '3':{ mask: '3', label: '3 - CELULAR'},
    '4':{ mask: '4', label: '4 - CODIA'},
    '5':{ mask: '5', label: '5 - EMAIL'},
    '6':{ mask: '6', label: '6 - SOLO NUMERO'},
    '7':{ mask: '7', label: '7 - SOLO LETRAS'},
    '8':{ mask: '8', label: '8 - INMUEBLE'},
    '9':{ mask: '9', label: '9 - CODIGO SISTEMA CAASD'},
    '10':{ mask:'10', label: '10 - FECHA'},
    '11':{ mask:'11', label: '11 - HORA'},
    '12':{ mask:'12', label: '12 - DECIMAL'},
    '13':{ mask:'13', label: '13 - EXPEDIENTE CAID'},
    '14':{ mask:'14', label: '14 - NSS INABIMA'},
    '15':{ mask:'15', label: '15 - CARGA EDESUR'},
    '16':{ mask:'16', label: '16 - MONTO INABIMA'},
    '17':{ mask:'17', label: '17 - LATITUD'},
    '18':{ mask:'18', label: '18 - LONGITUD'},
    '19':{ mask:'19', label: '19 - MAYUSCULAS'},
    '20':{ mask:'20', label: '20 - CEDULA INABIMA'},
    '21':{ mask:'21', label: '21 - EXPEDIENTE CAID'},
    '22':{ mask:'22', label: '22 - FECHA NO MAYOR ACTUAL'},
    '23':{ mask:'23', label: '23 - FECHA NO MENOR ACTUAL'},
    '24':{ mask:'24', label: '24 - EDAD NO MAYOR AL ENVIADA'},
    '25':{ mask:'25', label: '25 - FECHA NO MENOR ESPECIFICADA'},
    '26':{ mask:'26', label: '26 - FECHA DIA LABORABLE'},
    '27':{ mask:'27', label: '27 - MONTO GENERAL NO NEGATTIVO'},
    '28':{ mask:'28', label: '28 - ENTERO NO NEGATIVO'},
    '29':{ mask:'29', label: '29 - FECHA DE VUELO MENOR A 48 HORAS'},
    '30':{ mask:'30', label: '30 - FECHA MENOR A 48 HORAS(OTRO ID)'},
    '31':{ mask:'31', label: '31 - FECHA DE EMBARQUE'},
    '32':{ mask:'32', label: '32 - VALIDACION DE MAESTRO Y SOLICITUD ABIERTA'},
    '33':{ mask:'33', label: '33 - VALIDAR DECIMAL ENTERO ENTRE 0 -100'},
    '34':{ mask:'34', label: '34 - CODIGO INNMUEBLE'},
    '35':{ mask:'35', label: '35 - PLACA'},
    '36':{ mask:'36', label: '36 - VALIDACION INVI'},
    '37':{ mask:'37', label: '37 - VALIDACION JUNTA DE RETIRO FFAA'},
    '38':{ mask:'38', label: '38 - VALIDACION DE CONTRATO COAAROM'},
    '39':{ mask:'39', label: '39 - VALIDACION DE CONTRATO CORAAVEGA'},
    '40':{ mask:'40', label: '40 - MASCARA PERSONALIZADA'},
    '41':{ mask:'41', label: '41 - CONSULTAR LOS BALANCES DE LOS CLIENTES DE INAPA'},
    '42':{ mask:'42', label: '42 - VALIDACION DE CONTRATO CORAAMOCA'},
    '43':{ mask:'43', label: '43 -  VALIDACION DE MATRICULA UASD'},


}