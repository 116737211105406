import React, { Component } from 'react';
import { MDBRow,MDBBtn, MDBCol,MDBIcon,MDBModal,MDBModalBody, MDBModalHeader,MDBModalFooter } from "mdbreact";
import { TDate,TText,TTime } from "../form/index";
import {putStatusMask} from "../../Helpers/helpers";
import {toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ResumenCardMask} from'./resumenCardMask';
import {MaskFieldOptions} from './maskFieldOptions';
import {Badge} from'./maskBadges';
import MaskGridFields from './maskGridFields';
import {MaskSelectOption} from './maskSelectOption'
import './css/index.scss';
import './css/tableMask.scss';
import config from '../../config';
import { Mask as mask } from "../../data/index";
const {saveMask, GetLatestMaskVersion, GetAllMaskVersionsPerForm} = mask
class Mask extends Component {
  constructor(props){
    super(props)
    this.state = {
      modal: false,
      addToResumen: [],
      arrayWithMasks: [],
      ToInput: '',
      idForm: '',
      getUnique:'',
      active: false,
      mask: -1,
      data: [] ,
      name:'',
      actualName:'',
      count:0,
      actualTextValue:'',
      oldValueMask:'empty',
      progress: 0,
      message: false,
      arrayMask: [], 
      removePrevious:'',
      arrayMaskShow:[],
      arrayMaskShowToSave:[],
      setId:[],
      label:'',
      textValue:'',
      arrayNames:[],
      asignTemporalMask:[],
      removeSelector:'',
      oldIndex:0,
      versionSelect:'noVersion',
      quit:"",
      selected:false,
      entityState: [],
      fieldSelected:[],
      idSelected:[],
      idContainer:[],
      fieldsLength:[],
      maskVersionsGrid:[],
      maskVersions:[],
      maskParam: '',
      modalOptions:false,
      modalGrids:false,
      gridsConfig:'',
      version:'',
    }
    this.addToResumen = this.addToResumen.bind(this);
    this.ToInput = this.ToInput.bind(this)
    this.idForm = this.idForm.bind(this);
    this.mask = this.mask.bind(this);
    this.removePrevious = this.removePrevious.bind(this);
    this.setMaskVersion = this.setMaskVersion.bind(this);
    this.quitStatusMask = this.quitStatusMask.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.toggleGridFields = this.toggleGridFields.bind(this);
    this.saveFieldConfig =  this.saveFieldConfig.bind(this);
    this.asignConfig = this.asignConfig.bind(this);
    this.addGridsToResumen = this.addGridsToResumen.bind(this);
   }
  async ToInput(textValue,id,name, oldValueMask, active,label){
    if(this.state.mask===-1){
      return
    }
    this.state.idSelected.push(name)
    // let setConfig = document.getElementsByClassName('setEnabledBtn'+id)[0]
    for(const i in this.state.fieldSelected){
      if(this.state.fieldSelected[i] === name )
        return
    }
    this.state.fieldSelected.push(name)
    this.state.arrayNames.push(name)
    // this.state.setId = await GetFormularyBySE(this.props.idForm)
    let removeSelector = document.getElementById('Remove');
    removeSelector.removeAttribute("disabled",false);
    this.state.active = active;
    this.setState({ToInput: id })
    let selectDisable = document.getElementsByTagName('select');
    selectDisable[0].setAttribute("disabled",true);
    let element = document.getElementsByName(name)[0];
    this.setState({asignTemporalMask:[element.name]})
    let count = this.state.count;
    try{
      this.setState({name:name})
    document.getElementsByClassName(name)[0].innerHTML =  'Mascara actual:'+putStatusMask(this.state.mask)
    }catch(e){}
    let temporalMask = '';
    this.state.data[0].forEach(function(value){
      if(value['name']===name && count < 1 ){
        temporalMask= parseInt(textValue)
        element.setAttribute("style", "display:block; border-style: dotted;border-width: 3px;"
        + "border-color:#16B9B3; background-color:lightgreen;");
      }
    });
    this.state.asignTemporalMask.push(temporalMask);
    this.state.textValue = textValue
    this.state.label = label
    this.state.actualName = name
    this.state.actualTextValue = textValue
    this.state.oldValueMask = oldValueMask
    this.addToResumen(label,textValue,this.state.actualName)
   }

   asignConfig(value){
    this.setState({gridsConfig:value})
    for(const i in value){
      if(value[i] !== undefined)
        this.addGridsToResumen(value[i].label,value[i].mask, value[i].name)
    }
    this.toggleGridFields()
    toast.success('Mascaras en Grid añadidas', {
      position: toast.POSITION.BOTTOM_CENTER
    })
  } 
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleOptions = (e) =>{
    this.setState({
      modalOptions: !this.state.modalOptions
    }); 
  }
  idForm(e){
    this.setState({
      idForm: e.target.value
    })
  }
  saveFieldConfig(arrayFields){
    this.setState({
      fieldsLength: arrayFields
    })
  }
  mask(e){
    this.setState({
    mask: e.target.value,
    maskParam:  e.target.value === '26'? 1: ''
    })
  }
  versionSelect(e){
    this.setState({
    versionSelect: e.target.value
    })
  }

  fields(data){
    let SelectedAndRadios = []
    let field = [];
    let grids = [];
    for (const i in data) {
        for (const key in data[i]) {
            if(data[i][key].type==='grid'){
              let nameGrid = data[i][key]['name'] 
              data[i][key]['fields'].forEach(function(arrays){
                arrays['name'] = arrays['name'] +'-'+nameGrid
                grids.push(arrays)
              })
            }
          field.push(data[i][key])
        }
     }
     /*Agregando grids al state para pasarlo como props al componente de grids*/
     this.setState({
       grids: grids
     })
     /**/
     return [field,SelectedAndRadios]
  }
  toggleGridFields = (e) =>{
    this.setState({
      modalGrids: !this.state.modalGrids
    }); 
  }
  refresh(){
    let name =  this.state.name;
    let count = this.state.count;
    let element = document.getElementsByName(name)[0];
    if(element !==null && element !== undefined ){
      let oldValue = this.state.oldValueMask;
      this.state.data[0].forEach(function(value){
        if(value['name'] === name && count !== 1)
              value['Mask'] = parseInt(oldValue);
      });
    }
    this.setState({
      count: 0,
      })
      let selectDisable = document.getElementsByTagName('select');
      selectDisable[0].removeAttribute("disabled",false);
      this.setState({
        active:false
      })
  }
  addToResumen(label,textValue,name){
    label = this.state.label
    textValue = this.state.textValue
    name = this.state.actualName
    let textarea = document.getElementById("resumenArea");
    this.state.arrayMask.push('\\'+textValue+'%'+this.state.maskParam+'%'+'\\'+name)
    this.state.arrayMaskShowToSave.push('\\Mask-' +textValue+'%'+this.state.maskParam+'%'+' '+ name +  '=>' + [label.replace(':','')])
    this.state.arrayMaskShow.push('Mask-' +textValue+'%'+this.state.maskParam+'%'+' '+ name +  '=>' + [label.replace(':','')] +'\n')
 
    textarea.value= this.state.arrayMaskShow;
    for(const valores in this.state.arrayMask){
      if(textarea.value==='')
        textarea.value += valores 
    }
    this.refresh()
  }
  addGridsToResumen(label,mask,name){
    let textarea = document.getElementById("resumenArea");
    if(name!==undefined)
        textarea.value += 'Mask-'+mask+'=>'+name.substring(0,10)+'...'+'\n';
    }
  removePrevious(names){
    let insideText = document.getElementById('resumenArea');
    try{
      if (this.state.arrayMask.length > 0 &&  this.state.arrayMaskShow) {
        this.state.arrayMaskShow.pop(); this.state.arrayMask.pop(); this.state.arrayMaskShowToSave.pop();
        this.state.fieldsLength.pop();
        this.state.idSelected.pop();
        insideText.value = ''
          let element = document.getElementsByName(names[names.length-1])[0];
          this.quitStatusMask(element.name,this.state.data[0],this.state.data[0])
          this.state.arrayNames.pop(); this.state.fieldSelected.pop();
          element.removeAttribute("style", true)
          for(let i =0 ; i < this.state.arrayMaskShow.length; i++){
            insideText.value +=  this.state.arrayMaskShow[i]
          }
       this.refresh();
      } else {
        let element = document.getElementsByName(names[names.length-1])[0];
        element.setAttribute("style", "")
      }
    }catch(e){
      return
    }
  }
  quitStatusMask(name,data,maskGrid){
    for(const i in data){
     if(name===data[i]['name']){
      document.getElementsByClassName(name)[0].innerHTML =  'Mascara actual: Ninguna '
      }
      if(maskGrid[i].type==='grid')
          document.getElementsByClassName(name)[0].innerHTML =  'Mascara actual: Ninguna'
    }
  }
   async componentWillReceiveProps(props){
    const allMaskVersions =  await GetAllMaskVersionsPerForm(this.props.id);
    this.updateState(props)
    this.setState({data: this.fields(this.props.data)})
    this.setState({maskVersions:allMaskVersions.data.mask})
    this.setState({searchData: this.state.data[0]})
  
  }

  setMaskVersion(e){
    for(let i =0 ;i < this.state.arrayNames.length;i++ ){
      this.removePrevious(this.state.arrayNames[i])
    }
 
    this.setState({
      arrayMask: [],
      arrayMaskShow: [],
      arrayMaskShowToSave:[],
      fieldsLength:[]
    })
    let index = e.target.value
    let fieldsLength = []
    try{
      fieldsLength = this.state.maskVersions[index]['fieldLength'].split(",")
    } catch(e){
    }
    this.setState({oldIndex:index})
    let array,element, maskDummy = [], maskDummy2 = [], maskArray = [],
    formatedFieldsMasked, formatedMaskedToSave
    let textarea = document.getElementById("resumenArea");
    this.setState({
      version:e.target.value
    })
    if(e.target.value==='noVersion'){
      
      this.setState({
        maskVersionsGrid: [],
        arrayMask: [],
        arrayMaskShow: [],
        arrayMaskShowToSave:[],
        fieldsLength:[]
      })
      textarea.value=""
      for(let i = 0; i <this.state.arrayNames.length; i++){
        document.getElementsByName(this.state.arrayNames[i])[0].setAttribute("style","")
        try{
          document.getElementsByClassName(this.state.arrayNames[i])[0].innerHTML =  'Mascara actual: Ninguna '
        }catch(e){}
      }
      toast.error('El Campo esta vacio', {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
    if(e.target.value!=='noVersion'){
      this.setState({
        maskVersionsGrid: JSON.parse(this.state.maskVersions[index]['fieldsGridMasked'])
      })
    }

    let arrayFieldLength;
    try{
      arrayFieldLength = [this.state.maskVersions[index]['fieldLength'].substring(1).split(",")]
    }catch(e){

    }
    this.state.fieldsLength = arrayFieldLength
    for(let i = 0; i <this.state.arrayNames.length; i++){
      document.getElementsByName(this.state.arrayNames[i])[0].setAttribute("style","")
      try{
        document.getElementsByClassName(this.state.arrayNames[i])[0].innerHTML =  'Mascara actual: Ninguna '
      }catch(e){}
    }
    for(let i = 0; i <this.state.arrayNames.length; i++){
      document.getElementsByName(this.state.arrayNames[i])[0].setAttribute("style","")
    }
    if(this.state.maskVersions[index]!==undefined){
      if(this.state.maskVersions[index]['fieldsMasked'][0] ==='D'){
        array = this.state.maskVersions[index]['fieldsMasked'].split('D')
      }
      else{
        array = this.state.maskVersions[index]['fieldsMasked'].split('\\')
      }
      for(let i = 0; i < array.length; i++){
        element = document.getElementsByName(array[i])[0];
        this.state.arrayMask.push(array[i])
        maskDummy.push('\\'+this.state.arrayMask[i-1]+'\\' + this.state.arrayMask[i])
        if(array[i]!==null && element!==undefined){
          if(array[i].length > 3){
            this.state.arrayNames.push(array[i]);
            if(this.state.arrayMask[i-1]!==undefined && element.name!== undefined){
              try{
              document.getElementsByClassName(element.name)[0].innerHTML =  'Mascara actual: '+'No. '
              +putStatusMask(this.state.arrayMask[i-1])
              }catch(e){}
            }
            element.setAttribute("style", "display:block; border-style: dotted;border-width: 3px;"
            + "border-color:#16B9B3; background-color:lightgreen;");
          }
        }
      } 
      for(let x=1; x<maskDummy.length;x++){
        maskDummy2.push(maskDummy[x+1])
      }
      for(let y=0; y < maskDummy2.length;y++){
        maskArray.push(maskDummy2[y])
        y++;
      }
      formatedFieldsMasked = this.state.maskVersions[index]['formatedFieldsMasked'].split("\\")
      formatedMaskedToSave = this.state.maskVersions[index]['formatedFieldsMasked'].split("\\")
      formatedFieldsMasked.shift(); formatedMaskedToSave.shift()
      textarea.value= this.state.arrayMaskShow;
      for(let i = 0; i < formatedFieldsMasked.length; i++){
        textarea.value += formatedFieldsMasked[i] +'\n'
        this.state.arrayMaskShowToSave.push('\\'+formatedMaskedToSave[i])
      }
      
      this.setState({
        arrayMask:maskArray,
        arrayMaskShow:formatedFieldsMasked,
      })
    }
  } 
  updateState(newState){
    this.setState({
      date: newState
    })
  }
  /***************---- Enviar Configuracion  ----****************/
  async sendForm(e){
    try{
      if(this.state.arrayMask.length > 0 || this.state.gridsConfig.length > 0){
      this.toggle()
      let valor = await GetLatestMaskVersion(this.props.id);
      let newVersion = valor.data['mask']+1
      let finalArrayMask
      this.state.arrayMask.forEach(function(value){
        if(value !== undefined)
          finalArrayMask+= value
      })

      if(finalArrayMask !== undefined){
        finalArrayMask = finalArrayMask.substring(9,finalArrayMask.length);
      }else {
        finalArrayMask = [] 
      }
        let toStringMaskToSave='';
        for(let i = 0; i<this.state.arrayMaskShowToSave.length; i++){
          toStringMaskToSave+=this.state.arrayMaskShowToSave[i]
        }
       
        await saveMask(
          finalArrayMask, this.props.id,
          newVersion, toStringMaskToSave, ','+this.state.fieldsLength.toString(),
          JSON.stringify(this.state.gridsConfig)
          )

        toast.success(`Se han añadido las máscaras correctamente.`, {
          position: toast.POSITION.BOTTOM_CENTER
      });
      toast.success(`Version No.${newVersion}`,  {
        position: toast.POSITION.TOP_RIGHT
      })
        } else{
          toast.error('No se ha asignado ninguna mascara aún', {
            position: toast.POSITION.BOTTOM_CENTER
        })
      }
    }catch(e){
        this.toggle()
        toast.error('Error en la conexión con el servidor  o el formulario no se ha creado', {
          position: toast.POSITION.BOTTOM_CENTER
      });

    }
  }
  render() {
    return (
       this.state.data.length>0?(
        <MDBRow>
        <ToastContainer/>
        <MDBCol size="3"  className="border">
        <MDBBtn size="md" onClick={()=>window.location.href = "/Mask"} color="default">
        <MDBIcon size="4" fixed icon="arrow-right" flip="horizontal"/>
          Atrás
        </MDBBtn>
        <br></br>
        <label>ID del Formulario: <b>{this.props.formName}</b></label>
        <label>Nombre del Servicio: <b>{this.props.serviceName}</b></label>
          <br/><br/>
          <label>Seleccionar Mascara</label>
          
          <MaskSelectOption  mask={this.mask} value={this.state.mask} />
          {this.state.mask>-1&&(
            <div>
              <label>Parametro de mascara</label>
              <input className="form-control" value={this.state.maskParam} onChange={(e)=> this.setState({maskParam: e.target.value})}  placeholder="parametro de la mascara (Opcional)"/>
            </div>
          )}
            <MDBBtn color="default" id="Remove" size="sm" onClick={() =>this.removePrevious(this.state.arrayNames)}>
              Remover
            </MDBBtn>
            {/*deshabilitado por el momento*/}
              <MDBBtn color="default" size="sm" onClick={(e)=>this.toggleOptions(e)}>
                <MDBIcon icon="cog" size="1x" style={{color: '#ffffff'}} fixed />
              </MDBBtn>
            <MDBBtn color="default" id="gridButton"  size="sm" onClick={(e)=>this.toggleGridFields(e)}>
              Grids
            </MDBBtn>
            <MDBRow className="marginSelected">
                <MDBCol size="12" className="cursor">
                <br/>
                <label><b>Escoger una versión de Máscara</b></label>
                <select onChange={(e)=> this.setMaskVersion(e)}  
                  className="browser-default custom-select">
                  <option value="noVersion" >Sin Version</option>
                      {this.state.maskVersions !== undefined &&
                        this.state.maskVersions.length >0 &&
                        this.state.maskVersions.map((masks,i)=>
                        <option key={i} value={i}>Version:{
                        masks.maskVersion} || {masks.createdAt && masks.createdAt.split('T')[0]} 
                        {masks.createdAt.split('T')[1].substring(0, 0)
                          }</option>
                        )
                      }
                </select>
                <br/><br/>
                <ResumenCardMask  />
                {/* <UploadMask value="" /> */}
                <MDBBtn color="primary" size="md" onClick={() =>this.toggle()}>Guardar Configuracion</MDBBtn>
                </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol size="9" className="center-colModal">
          <MDBRow className="marginSelected">
          {this.state.data.map((array,x) =>
              this.state.data[x].map((field, i) =>
                field.type === 'text'? (
                    <MDBCol size="3"  className="cursor" onClick={(e) => this.ToInput(this.state.mask, i ,field.name,
                    field.Mask, this.state.active, field.label,e)}> 
                            <span class="badge badge-primary">{
                            field.group}</span><br></br>
                        <Badge  name={field.name} maskValue={this.state.asignTemporalMask} i={i} />
                        <TText  key={i} class="refresh" oldValue={field.mask} id={i + field.name} field={field} />
                    </MDBCol>
                    ):
                    field.type === 'time' ? (
                        <MDBCol size="3" className="cursor"  onClick={(e) => this.ToInput(this.state.mask, i ,
                        field.name, field.Mask, this.state.active, field.label)}>
                            <span class="badge badge-primary">{
                            field.group}</span><br></br>
                        <div class="mt-3"></div>
                        <Badge  name={field.name} maskValue={this.state.asignTemporalMask} i={i} />
                            <TTime key={i} oldValue={field.mask}  id={i + field.label} field={field}/>
                        </MDBCol>
                    ):
                    field.type === 'date' &&(
                        <MDBCol size="3" className="cursor disable-fields"  onClick={(e) => this.ToInput(this.state.mask, i ,field.name,
                        field.Mask, this.state.active, field.label)}>
                            <span class="badge badge-primary">{
                            field.group}</span><br></br>
                        <div class="mt-3"></div>
                        <Badge  name={field.name} maskValue={this.state.asignTemporalMask} i={i} />
                            <TDate key={i} oldValue={field.mask} id={i + field.label} field={field}/>
                        </MDBCol>
                    ) 
                  )
                )
            }
          </MDBRow>
          </MDBCol>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg" >
                  <MDBModalHeader toggle={this.toggle}>
                    <center>Guardar Máscaras</center>
                  </MDBModalHeader>
                  <MDBModalBody>
                  <center> ¿Desea guardar las Máscaras Añadidas?</center>
                  <div>
                    <center>
                    <b>{this.state.arrayMaskShow}</b>
                    </center>
                  </div>
                  </MDBModalBody>  
                  {/* checkbox to export */}
                  <MDBCol size="4">
                    {/* <label>
                    &nbsp;&nbsp;&nbsp;<input type="checkbox" id="IsChecked"/>&nbsp;&nbsp;&nbsp;Exportar Configuracion
                    </label> */}
                  </MDBCol>
                  <MDBModalFooter>
                    <MDBBtn  color="secondary"  onClick={()=> this.toggle()}>Cancelar</MDBBtn>
                    <MDBBtn color="success" onClick={()=> this.sendForm()}>Guardar</MDBBtn>
                  </MDBModalFooter>
            </MDBModal>
            <MaskFieldOptions 
            data={this.state.data[0]}
            toggleFunction={this.toggleOptions}
            saveFieldConfig = {this.saveFieldConfig}
            toggleOptions={this.state.modalOptions}
             />
            <MaskGridFields 
            asignConfig={this.asignConfig}
            toggleGridFields={this.toggleGridFields}
            modalGrids={this.state.modalGrids}
            gridFields={this.state.grids}
            mask={this.state.mask}
            maskVersionsGrid={this.state.maskVersionsGrid === ""? []: this.state.maskVersionsGrid }
            version={this.state.version}
             />
        </MDBRow>
       ): <MDBIcon icon="cog" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />
    );
  } 
}
export default Mask;